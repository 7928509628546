<template>
  <div />
</template>

<script>

export default {
  name: 'Sso',
  components: {

  },
  data () {
    return {

    }
  },
  mounted () {

  },
  created () {
    this.querySso()
  },
  methods: {
    querySso() {
      let {code,state, namespace} = this.$route.query
      if(!code || !namespace) {
        this.$message.warning('SSO 传递参数有误')
        return
      }
      this.$apiDataFilter.request({
        api: 'portal.querySso',
        method: 'post',
        data: {
          code,
          namespace:(namespace||"").toUpperCase()
        }
      }).then(res=>{
        if(res.success) {
          const {authenticationKey,loginType,userId} = res.data
          location.href=`/login?redirect_uri=${decodeURIComponent(state)}&token=${authenticationKey}&loginType=${loginType}&userId=${userId}`
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
