<template>
  <div class="page-login" :class="sso?'sso':''">
    <div class="login-left">
      <div class="title">
        <img
          src="../assets/images/acc.png"
          alt=""
        >
        <div class="sub-title">
          <p class="bus">
            Accenture Business
          </p>
          <p class="plat">
            Application Platform
          </p>
          <p class="desc">
            埃森哲应用底座
          </p>
        </div>
      </div>
    </div>
    <div v-if="!sso" class="login-form">
      <div class="login-title">
        用户登录
      </div>
      <ac-login
        ref="login"
        class="login-form-content"
        :option="option"
        @submit="submitForm"
      >
        <div class="box">
          <el-button
            class="register"
            type="text"
            style="float: right;"
            @click="goRegister"
          >
            注册
          </el-button>
        </div>
        <!-- <el-form-item label="是否租户">
           <el-select v-model=""></el-select>
         </el-form-item> -->
      </ac-login>
    </div>
    <div v-if="sso" class="sso-tips">
      页面跳转中...
    </div>
  </div>
</template>

<script>

import {localSave,publicStartPath} from "feSystemHub";
import {mapActions,mapState} from "vuex";
export default {
  name: 'Login',
  data () {
    let _t = this
    const isSame = (val)=>{
      const strs = val.split('')
      return strs.every(str=>str===strs[0])
    }
    return {
      option:{
        labelWidth: 130,
        width: 350,
        time: 60,
        codeType: 'phone', //phone为手机验证码/img为图片验证码
        column: {
          username: {
            label: '用户名',
            prop: 'telephone',
            placeholder:'请输入用户名',
            autocomplete: 'off',
            rules: { required: true, message: '请输入用户名', trigger: 'blur' }
          },
          password: {
            label: '密码',
            prop: 'password',
            placeholder:'请输入密码',
            autocomplete: 'off',
            rules: [
              { required: true, message: '请输入密码', trigger: 'blur' },
              { validator: (rule,val,callback)=>{
                if(!/^(?=.*[a-zA-Z]).{6,20}$/.test(val)) {
                  callback('密码长度6-20位且需包含字母')
                  return
                }
                if(isSame(val)) {
                  callback('密码中的字符不能完全相同')
                  return
                }
                if(val == _t.$refs.login.form.username) {
                  callback('密码不能和用户名相同')
                  return
                }
                callback()
              },trigger: "blur"}
            ]
          },
          code: {
            hide: true
          },
          tenantType: {
            label: '是否租户',
            prop: 'tenantType',
            rules: { required: true, message: '请选择是否为租户', trigger: 'change' }
          },
          tenantCode: {
            label: '租户编码',
            prop: 'tenantCode',
            placeholder:'请输入租户编码',
            rules: { required: false, message: '请输入租户code', trigger: 'blur' },
            hide: true
          }
        }
      },
      sso: false
    }
  },
  computed: {
    ...mapState({
      userInfo:({portal})=>portal.userInfo,
      personalUrl: ({portal})=>portal.personalUrl.url,
      enterpriseUrl: ({portal})=>portal.enterpriseUrl.url
    })
  },
  created () {
    this.checkSSO()
  },
  mounted () {},
  methods: {
    submitForm (form) {
       this.login(form)
    },
    login(data){
      this.$apiDataFilter.request({
        api:'portal.login',
        method:'post',
        data
      }).then((res)=>{
        localSave('token',res.data)
        const {redirect_uri=''} = this.$route.query
        if (redirect_uri){
          window.location.href = decodeURIComponent(redirect_uri)
        }else {
          this.$router.push('/')
        }
      })
    },
    goRegister () {
      this.$router.push('/register')
    },
    checkSSO() {
      const { loginType,userId,token,redirect_uri=''} = this.$route.query
      // 说明是SSO已经登录了回调过来的地址
      if(loginType && token) {
        this.sso = true
        localSave('token',token)
        this.setUserCenterUser({id: userId,loginType}).then(()=>{
          if(loginType === 'personal') {
            if(this.userInfo.credentialCode) {
              this.$router.push('/application/user/personal/info')
            }else {
              this.$router.push('/application/user/personal/identity')
            }
          }else {
            // 跳转公司信息页面
            this.$router.push('/application/user/company')
          }
        })
        // 如果是访问用户中心未登录跳转的登录界面，需要重新跳转到SSO登录界面
      }else if(redirect_uri&&redirect_uri.indexOf('/application/user/')>-1) {
        // 跳转SSO登录地址
        // 企业
        if(redirect_uri.indexOf('/application/user/company')>-1) {
          location.href= this.enterpriseUrl
        }else {
          location.href= this.personalUrl
        }
        /* if(process.env.NODE_ENV === 'development') { // 测试环境SSO登录
        }else {
          location.href='https://personal.sso.iwonderful.top:8080/authorize?response_type=code&scope=openid&client_id=userCenter&redirect_uri='+decodeURIComponent('https://ac.cloud-platform-prd.web/sso')+'&state='+decodeURIComponent(redirect_uri)
        } */
      }
    },
    ...mapActions({
      setUserCenterUser:'setUserCenterUser'
    })
  }
}
</script>

<style lang="scss" scoped>
.page-login {
  height: 100vh;
  width: 100vw;
  display: flex;
  .login-left {
    position: relative;
    height: 100%;
    width: 500px;
    background: url(../assets/images/login-bg.png) no-repeat 100% 100%;
    background-color: #2f1f62;
    .title {
      padding-top: 80px;
      padding-left: 80px;
      color: #fff;
      .sub-title {
        line-height: 42px;
        font-size: 32px;
        .bus {
          margin-top: 60px;
          color: #a100ff;
          font-weight: 500;
        }
        .desc {
          margin-top: 8px;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }
  .login-form {
    display: flex;
    flex: 1;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    width: 100%;
    padding: 60px 80px;
    .login-title{
      margin: 0 auto;
      margin-bottom: 20px;
      width: 350px;
      font-size: 18px;
      color: #303133;
    }
    .login-form-content{
      margin: 0 auto;
      .register{
        cursor: pointer;
      }
    }
  }
  .el-form{
    margin: 0 auto;
    width: 350px;
    .btn-login{
      width: 100%;
    }
  }
}
.sso{
  background: #2f1f62;
  .sso-tips{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: bold;
    font-size: 52px;
  }
}
</style>
