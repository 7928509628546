<template>
  <div class="logout">
    <p class="tips">
      退出中...
    </p>
  </div>
</template>

<script>
// import moduleName from 'module'
import {mapState} from 'vuex'
import {localClear} from 'feSystemHub'
export default {
  name: '',
  components: {

  },
  data () {
    return {

    }
  },
  computed:{
    ...mapState({
      userInfo: ({portal})=> portal.userInfo
    })
  },
  mounted () {

  },
  created () {
    this.logout()
  },
  methods: {
    logout() {
      localClear()
      window.location.href = '/login'
    }
  },
}
</script>

<style lang="less" scoped>
.logout{
  position: relative;
  width: 100vw;
  height: 100vh;
  background: url(../assets/images/login-bg.png) no-repeat center;
  background-color: #2f1f62;
  .tips{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    font-weight: bold;
    font-size: 22px;
  }
}
</style>
